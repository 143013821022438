<template>
  <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
    <div id="page-content" v-if="!viLoader">
      <div class="webNew-dispflex">

        <div class="backbox">
          <div class="back-arrow" @click="$router.go(-1)">
            <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>


          </div>

        </div>
        <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
          School Plan
        </h4>
      </div>
      <div class="row mt-2">
        <div class="col-lg-4">
          <!-- <span> Select Country :</span>
          <span style="margin-left: 19px;"> <select id="deptlistid"
              style="border-radius: 4px;text-align: center;background: white;">
              <option :value="null">-- Select --</option>
             
            </select></span> -->

        </div>
        <div class="col-lg-4">
          <span style="color: #2954a2;">
            <h1 style="font-size: 1.5rem;
                color: #2954A2;
                font-family: Poppins;
                /* font-size: 40px; */
                font-style: normal;
                font-weight: 600;
                /* line-height: normal; */
                text-transform: uppercase;">Vidhyaan Subscription Plans</h1>
          </span>
        </div>
        <div class="col-lg-4">
          <span style="font-size: 20px;color: #000;"> Quarterly</span>
          <!-- Quarterly -->
          <span style="margin-left: 4px;">
            <label class="switchNew">
              <input @input.prevent="checkInterval(planInterval)" type="checkbox" v-model="planInterval" checked>
              <span class="sliderNew round"></span>
            </label>
          </span>
          <span style="font-size: 20px;color: #000;"> Yearly</span>
          <span> ( Save upto 25% Off)</span>

          <!-- Yearly -->
        </div>
      </div>
      <!-- <div class="row mt-3">
                   <div class="col-sm-6 col-lg-3" style="margin-top: 1%;"  >
                   <div class="white-box org mb-2" style="height: 100%;background-color: #ece2de">
                       <div >
                         sss
                       </div>
                     </div>
                     
                       
                      
                   </div>
               </div> -->

      <div class="row">
        <div class="col-lg-12">
          <div>

            <div class="p-3 widjetcontent">
              <div class="resfound text-center m-0">

                <!-- <div class="top-tabs-container" id="maintab" style="background: #2954a2">
       <label for="schOnline" @click.prevent="getPlanDetails(indextr,product.product_id)" class="tabbtn" :class="{ tabactive: currentActiveTab == indextr }" :key="indextr" v-for="(product, indextr) in productList">{{ product.name }}</label>
      
     </div> -->
              </div>

              <div class="innerpagecont">
                <div>
                  <div id="subtabContainer">
                    <!-- Tab Container -->
                    <input class="tab-radio" id="schOnline" name="main-group" type="radio" checked="checked" />
                    <div style="width: 87%;
   margin: 0 auto;
   text-align: center;
   padding: 30px 0;">
                      <!-- <div class="sub-tabs-container clearfix">
             <label for="sub-tab-1" class="subbtn" @click.prevent="filterByFrequency(typeIndex,type)" :class="{ subactive: currentActiveType == typeIndex }"  :key="typeIndex" v-for="(type, typeIndex) in planTypes">{{ type }}</label>
           </div> -->
                      <!-- Sub Tab -->
                      <input class="tab-radio" id="sub-tab-1" name="sub-group" type="radio" checked="checked" />
                      <div class="sub-web-tab-content">
                        <ul>
                          <li>
                            <div style="background-color: white;" class="singleprice" :key="planIndex"
                              v-for="(plan, planIndex) in planList">
                              <div v-if="plan.plan_code == 'V50' || plan.plan_code == 'VY50'" style="border-radius: 12px 12px 0px 0px;
   background: linear-gradient(180deg, #007AFF 0%, #004999 100%);
   color: white;
   text-align: center;
   width: 300px;
   position: absolute;
   top: 28px;">Most Preferred </div>
                              <div class="header " v-if="plan.custom_fields.length > 0">
                                <h2 style="padding-bottom: 12px;
   border-bottom: 1px solid;
   font-size: 1.2rem;
   text-align: center;
   color: rgb(74, 74, 157);">{{ plan.name }}</h2>

                                <h1 style="text-align: center;font-size: 2rem;">
                                  <span style="text-align: center;font-size: 1.5rem;" v-if="userCurrencycode == 'INR'">$
                                    {{ getPriceValue(plan.plan_code) }}</span>
                                  <span style="text-align: center;font-size: 1.5rem;"
                                    v-if="userCurrencycode == 'AED'">AED {{ getPriceValue(plan.plan_code) }}</span>
                                  <span style="text-align: center;font-size: 1.5rem;" v-if="userCurrencycode == 'CAD'">$
                                    {{ getPriceValue(plan.plan_code) }}</span>
                                  <span style="text-align: center;font-size: 1.5rem;" v-if="userCurrencycode == 'EUR'">€
                                    {{ getPriceValue(plan.plan_code) }}</span>
                                  <span style="text-align: center;font-size: 1.5rem;" v-if="userCurrencycode == 'USD'">$
                                    {{ getPriceValue(plan.plan_code) }}</span>
                                  </br>

                                  <span>{{ getNoOfStudents(plan.custom_fields) }} Students <br>Billed {{
                                    getbillingName(plan.interval, plan.interval_unit) }}</span>
                                </h1>
                                <button
                                  :class="((checkSubPlan(plan.plan_code)) || (activeMainPlan)) ? 'regbtn' : 'regbtn regredbtn'"
                                  :style="((checkSubPlan(plan.plan_code)) || (activeMainPlan)) ? 'color : #10f010;' : 'color : black'"
                                  :disabled="(checkSubPlan(plan.plan_code)) || (activeMainPlan)"
                                  @click.prevent="goOnlinePayment(plan)">
                                  {{ ((checkSubPlan(plan.plan_code)) || (activeMainPlan)) ? 'SUBSCRIBED' : 'SUBSCRIBE'
                                  }}
                                </button>
                              </div>
                              <div class="featCont mt-4">
                                <div style="color: #2954A2;
leading-trim: both;
text-edge: cap;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;" v-for="(value, textIndex) in getSplitWise(plan.store_markup_description)" key="textIndex">

                                  <!-- <i class="fas fa-check"></i>
                       <i class="fas fa-check"></i>
                       <i class="fas fa-check"></i> -->
                                  <!-- <i class="fas fa-check" style="font-size: 12px;color: #4343d5;"></i>  -->
                                  <p style="color: #2954A2;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;"> <span>&#x2713;</span> <span>{{ value }}</span> </p>
                                </div>
                                <!-- color: #2954A2;

leading-trim: both;

text-edge: cap;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal; -->

                                <!-- <ul :key="textIndex" v-for="(value, textIndex) in getSplitWise(plan.store_markup_description)">
                       <li>
                         <p>{{ value }}</p>
                       </li>
                      
                     </ul> -->
                              </div>
                            </div>

                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import ViService from "@/services/ViService";
import secureUI from "../utils/secureUI";

export default {
  name: "vi-admission-home",

  data() {
    return {
      productList: [],
      planList: [],
      schoolDetails: {},
      masterPlanList: [],
      currentActiveTab: 0,
      currentProductId: null,
      planTypes: [],
      currentActiveType: 0,
      activeSubscriptions: [],
      activeMainPlan: false,
      planInterval: false,
      userCurrencycode: '',
      viLoader: false
    };
  },
  computed: {
    planDetails() {
      return this.$store.getters.currentPlanDetail;
    },
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: (brwHeight - 90) + 'px'
      };
    },
  },
  created() {
    this.initComp()
    this.getdetails()
  },

  methods: {
    initComp() {
      let data = this.$store.getters.currentPlanDetail;
      this.activeSubscriptions = data || []

      let checkMainPlan = data.filter(x => x.plan_code == 'V50')

      if (checkMainPlan && checkMainPlan.length > 0) {
        this.activeMainPlan = true
      }

    },
    async getdetails() {
      await this.getSchollDetails();
      this.getProductDetials();

    },
    checkSubPlan(plan) {
      let data = this.activeSubscriptions.filter(x => x.plan_code == plan)

      if (data && data.length > 0) {
        return true
      } else {
        return false
      }
    },
    getSplitWise(data) {
      var arrayOfStrings = data.split('|');
      return arrayOfStrings
    },
    previousRoute() {
      let data = localStorage.getItem('previousTab');
      localStorage.setItem("activeTab", data);
      localStorage.setItem("previousTab", data);
      this.$router.go(-1)
    },
    redirectRoute(vipath) {

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        if (this.$route.path !== vipath) this.$router.push({ path: vipath });
      } else {
        localStorage.removeItem('user');
        this.$router.push('/login');
      }
    },
    getSavePrice(data) {
      if (data.length > 1) {
        let value = data.filter(x => x.label == 'Save_1')
        if (value) {
          return value[0].value_formatted
        } else {
          return 0
        }
      } else {
        return 0
      }

    },
    getNoOfStudents(data) {
      if (data.length > 0) {
        let value = data.filter(x => x.api_name == 'cf_no_of_students')
        if (value) {
          return value[0].value_formatted
        } else {
          return 0
        }
      } else {
        return 0
      }
    },
    getOrginalPrice(data) {
      if (data.length > 1) {
        let value = data.filter(x => x.label == 'MRP_1')
        if (value) {
          return value[0].value_formatted
        } else {
          return 0
        }
      } else {
        return 0
      }
    },
    getPlanDetails(index, productId) {
      if (this.currentActiveTab != index) {
        this.currentActiveTab = index
        this.currentProductId = productId
        this.getPlanList();
      }
    },
    filterByFrequency(index, type) {

      this.planList = []
      // this.currentActiveType = index
      this.planList = this.masterPlanList.filter(x => x.frequencyType == type)

    },
    getbillingName(interval, interval_unit) {

      if (interval == 1 && interval_unit == 'months') {
        return 'every month'
      } else if (interval == 1 && interval_unit == 'years') {
        return 'annually'
      } else if (interval == 3 && interval_unit == 'months') {
        return 'Quarterly'
      } else if (interval == 6 && interval_unit == 'months') {
        return 'every 6 Months'
      } else if (interval == 1 && interval_unit == 'weeks') {
        return 'every week'
      }

    },
    checkInterval(data) {
      let value = ''
      if (!data) {
        value = 'Yearly'
      } else {
        value = 'Quarterly'
      }

      this.planList = []
      // this.currentActiveType = index
      this.planList = this.masterPlanList.filter(x => x.frequencyType == value)

      // "Quarterly","Yearly"
    },
    async goOnlinePayment(data) {

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$router.push('/login');
      } else {
        if (data.recurring_price == 0) {
          this.$router.push('/subscribe/enrollSchool');
        } else {

          let custName = userData.userDetails.firstName + " " + userData.userDetails.lastName

          let obj = {
            plan: {
              plan_code: data.plan_code,
              tax_id: null,
              "quantity": 10,
            },

          }
          if (userData.userDetails.account && userData.userDetails.account.customerId) {

            let quantity = this.$store.getters.getSubscriptionQuantity;
            obj.plan.quantity = quantity

            obj['customer_id'] = userData.userDetails.account.customerId

            const response = await ViService.viPost("/common/createSubscriptionAndCustomer", obj);

            if (response.isSuccess) {
              window.open(response.data.hostedpage.url)
            } else {
              this.$toasted.error(response.message);
              if (response.message == 'Your session has expired, please login') {
                localStorage.removeItem('user');
                this.$router.push('/login');
              }
            }
          } else {
            this.getPlanPriceAmount(data.plan_code)

          }



        }
      }
    },
    async getSchollDetails() {
      this.viLoader = true;

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viGetOnly(
          "/common/details?accountId=" + userData.userDetails.account._id
        );

        if (response.isSuccess) {
          const schoolResult = secureUI.secureGet(response.data);

          this.schoolDetails = schoolResult;
          this.userCurrencycode = this.schoolDetails.currencyCode || 'INR'

        } else {
          this.$toasted.error(response.message);
          if (response.message == 'Your session has expired, please login') {
            localStorage.removeItem('user');
            this.$router.push('/login');
          }
        }
      }
      // this.viLoader = false;
    },
    async getProductDetials() {

      const response = await ViService.viGetOnly("/app/getAllProducts");

      if (response.isSuccess) {
        if (response.data.products.length > 0) {
          const activeProducts = response.data.products.filter(x => x.status == 'active')
          if (activeProducts.length > 0) {
            this.productList = activeProducts
            this.currentProductId = this.productList[0].product_id
            this.getPlanList();
          }

        }

      } else {
        this.$toasted.error(response.message);
        if (response.message == 'Your session has expired, please login') {
          localStorage.removeItem('user');
          this.$router.push('/login');
        }
      }

    },
    getPriceValue(planCode) {
      if (planCode) {
        let data = this.priceListDetails.pricebook_items.find(x => x.product_code == planCode)
        if (data) {
          return data.pricebook_rate
        } else {
          return 0
        }
      }
    },
    async getPlanList() {
      let userData = secureUI.sessionGet("user");
      
      if (!userData) {
        this.$router.push('/login');
      } else {
        let code = this.schoolDetails.currencyCode || 'INR'
        this.priceListDetails = {};
        this.planList = [];
        this.planTypes = [];
        this.viLoader = false;
        const response = await ViService.viGetOnly(`/app/getPlanList?productId=${this.currentProductId}&currencyCode=${code}`);
        
        if (response.isSuccess) {

          if (response.data.allPlans.plans && response.data.allPlans.plans.length > 0) {

            for (let x of response.data.allPlans.plans) {
              for (let y of x.custom_fields) {
                if (y.placeholder == "cf_frequency_type") {
                  x['frequencyType'] = y.value
                }
              }
            }
            this.planTypes = response.data.allPlans.plans.map(item => item.frequencyType).filter((value, index, self) => self.indexOf(value) === index)
            
            this.masterPlanList = response.data.allPlans.plans
            this.planList = response.data.allPlans.plans.filter(x => x.frequencyType == this.planTypes[0])
          }

          if (response.data.pricelist.pricebook && response.data.pricelist.pricebook) {
            this.priceListDetails = response.data.pricelist.pricebook || {}
          }

        } else {
          this.$toasted.error(response.message);
          if (response.message == 'Your session has expired, please login') {
            localStorage.removeItem('user');
            this.$router.push('/login');
          }
        }
      }
    },
    async getPlanPriceAmount(planCode) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$router.push('/login');
      } else {
        let code = this.schoolDetails.currencyCode || 'INR'
       
        const response = await ViService.viGetOnly(`/app/getPlanPriceAmount?planCode=${planCode}&currencyCode=${code}`);

        if (response.isSuccess) {

          let urlObj = `${response.data.url}?cf_accountid=${userData.userDetails.account._id}&email=${userData.userDetails.email}&mobile=${userData.userDetails.phoneNo}`
          
          //  data.plan_code
          window.open(urlObj)

        } else {
          this.$toasted.error(response.message);
          if (response.message == 'Your session has expired, please login') {
            localStorage.removeItem('user');
            this.$router.push('/login');
          }
        }
      }
    }
  },
};
</script>

<style>
.switchNew {
  position: relative;
  display: inline-block;
  width: 68px;
  height: 25px;
  margin-bottom: -4px;
}

.switchNew input {
  opacity: 0;
  width: 0;
  height: 0;
}

.sliderNew {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.sliderNew:before {
  color: red;
  position: absolute;
  content: "";
  height: 18px;
  width: 21px;
  left: 13px;
  bottom: 4px;
  background-color: blue;
  transition: .4s;
}



input:focus+.sliderNew {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.sliderNew:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliderNews */
.sliderNew.round {
  border-radius: 34px;
}

.sliderNew.round:before {
  border-radius: 50%;
}
</style>
